import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const VerifyOtp = ({ onSubmit }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isError, setIsError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State untuk loading
  const [isResending, setIsResending] = useState(false); // State untuk mengelola pengiriman ulang OTP

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email, event_id } = useSelector((state) => state.registerEvent);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ];
    setOtp(newOtp);

    // Focus next input
    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }

    // Jika semua kotak terisi, otomatis kirim OTP
    if (newOtp.every((d) => d !== "")) {
      handleVerify(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      const prevInput = e.target.previousSibling;
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleVerify = (otpCode) => {
    const base_url = process.env.REACT_APP_BASE_URL;

    setIsLoading(true); // Set loading state ke true

    axios
      .post(`${base_url}/events/register/verify`, {
        event_id,
        email,
        otp: otpCode,
      })
      .then((response) => {
        setModalTitle("Success");
        setModalMessage("Your account has been verified successfully.");
        setShowModal(true);

        // Buka aplikasi iOS setelah verifikasi berhasil
        setTimeout(() => {
          // Coba buka aplikasi menggunakan skema URL, jika gagal, buka App Store
          window.location.href = "tracco://"; // Ganti dengan skema URL aplikasi Anda
          setTimeout(() => {
            window.location.href =
              "https://apps.apple.com/id/app/tracco/id6444603368";
          }, 500); // Tambahkan sedikit penundaan sebelum membuka App Store
        }, 1000); // Tambahkan sedikit penundaan sebelum membuka aplikasi
      })
      .catch((error) => {
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
        setShowModal(true);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state ke false setelah proses selesai
      });
  };

  const handleResendOtp = (e) => {
    e.preventDefault(); // Mencegah aksi default elemen <a>
    const base_url = process.env.REACT_APP_BASE_URL;

    setIsResending(true); // Set resending state ke true

    axios
      .post(`${base_url}/auth/otp/request`, {
        email,
      })
      .then((response) => {
        setModalTitle("Success");
        setModalMessage(response.data.message);
        setShowModal(true);
      })
      .catch((error) => {
        setModalTitle("Error");
        setModalMessage(error.response.data.message);
        setShowModal(true);
        setIsError(true);
      })
      .finally(() => {
        setIsResending(false); // Set resending state ke false setelah proses selesai
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleVerify(otp.join(""));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (modalTitle === "Success") {
      navigate("/uihm/verify-otp");
    }
  };

  return (
    <div className="container">
      <div className="content">
        <div className="logo">
          <img
            src="/logo.png"
            srcSet="/logo.png 1x, /logo@2x.png 2x"
            alt="Tracco Logo"
          />
          Tracco
        </div>

        <div className="header mb-d">
          <h1 className="title">Confirm Account</h1>
          <p className="text">
            Enter the code we sent to your registered email, to verify your
            account.
          </p>
        </div>

        <form className="form-register" onSubmit={handleSubmit}>
          <div className="form-group otp-inputs mb-24">
            {otp.map((data, index) => (
              <input
                key={index}
                type="text"
                name="otp"
                maxLength="1"
                value={data}
                className={`${data === "" ? "" : "active"}`}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={(e) => e.target.select()}
                required
              />
            ))}
          </div>
          <button
            className="btn btn-primary btn-confirm w-100"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </form>

        <div className="resend-otp mt-3">
          Haven’t received the code?{" "}
          <a href="#" onClick={handleResendOtp} aria-disabled={isResending}>
            {isResending ? "Resending..." : "Resend"}
          </a>
        </div>
      </div>

      {/* Bootstrap Modal */}
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal modal-alert fade show d-block"
            id="resultModal"
            tabIndex="-1"
            aria-labelledby="resultModalLabel"
            aria-hidden={!showModal}
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">{modalTitle}</h5>
                  {modalMessage}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-confirm w-100"
                    onClick={handleCloseModal}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyOtp;
