import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRegisterEvent } from "../../features/registerEventSlice";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const Register = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State untuk loading
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = () => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const event_id = 1;

    setIsLoading(true); // Set loading state ke true saat mulai proses register

    axios
      .post(`${base_url}/events/register`, { event_id, email })
      .then((response) => {
        // Simpan data event yang terdaftar di state
        dispatch(setRegisterEvent(response.data.data));
        // Navigasi ke halaman verifikasi OTP
        navigate("/uihm/verify-otp");
      })
      .catch((error) => {
        // Atur judul dan pesan modal berdasarkan pesan kesalahan dari server
        setModalTitle("Error");
        setModalMessage(
          error.response?.data?.message ||
            "An unexpected error occurred. Please try again."
        );
        setShowModal(true);
      })
      .finally(() => {
        // Set loading state ke false setelah proses selesai
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleRegister();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="banner-header">
        <img src="../img/banner.png" alt="Banner" />
      </div>

      <div className="container">
        <div className="content">
          <div className="header-large">
            <h1 className="title">UI Half Marathon</h1>
            <p>
              Join the UI Half Marathon campaign and track your carbon emission
              before running in this exciting event! The event will be held on
              Sunday, September 8th 2024.
            </p>

            <p>
              Whether you're attending in person or running virtually, you can
              still support the cause of sustainability and contribute to a
              greener future.
            </p>
            <p>
              The campaign runs from September 5th to September 15th, 2024.
              Every step makes a difference!
            </p>
          </div>

          <form className="register-form" onSubmit={handleSubmit}>
            <div className="form-group mb-24">
              <label>Email</label>
              <input
                type="email"
                value={email}
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <button
              className="btn btn-primary btn-confirm w-100"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Register"}
            </button>
          </form>
        </div>
      </div>

      {/* Bootstrap Modal */}
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal modal-alert fade show d-block"
            id="resultModal"
            tabIndex="-1"
            aria-labelledby="resultModalLabel"
            aria-hidden={!showModal}
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">{modalTitle}</h5>
                  {modalMessage}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-confirm w-100"
                    onClick={handleCloseModal}
                  >
                    okay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Register;
