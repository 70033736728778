import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  event_id: null,
  email: "",
  otp: "",
};

const registerEventSlice = createSlice({
  name: "registerEvent",
  initialState,
  reducers: {
    setRegisterEvent(state, action) {
      const { event_id, email, otp } = action.payload;
      state.event_id = event_id;
      state.email = email;
      state.otp = otp;
    },
    clearRegisterEvent(state) {
      state.event_id = null;
      state.email = "";
      state.otp = "";
    },
  },
});

export const { setRegisterEvent, clearRegisterEvent } =
  registerEventSlice.actions;

export default registerEventSlice.reducer;
