// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import tripSlice from "../features/tripSlice";
import registerEventReducer from "../features/registerEventSlice";

const store = configureStore({
  reducer: {
    trip: tripSlice,
    registerEvent: registerEventReducer,
  },
});

export default store;
