import React, { useCallback, useState } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import SearchResults from "./SearchResults";

const StartIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6.66663C14 11.3333 8 15.3333 8 15.3333C8 15.3333 2 11.3333 2 6.66663C2 5.07533 2.63214 3.5492 3.75736 2.42399C4.88258 1.29877 6.4087 0.666626 8 0.666626C9.5913 0.666626 11.1174 1.29877 12.2426 2.42399C13.3679 3.5492 14 5.07533 14 6.66663Z"
      fill="#CB5C5C"
      stroke="#CB5C5C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.66663C9.10457 8.66663 10 7.7712 10 6.66663C10 5.56206 9.10457 4.66663 8 4.66663C6.89543 4.66663 6 5.56206 6 6.66663C6 7.7712 6.89543 8.66663 8 8.66663Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EndIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.00004C15 11.6819 12.0152 14.6667 8.33329 14.6667C4.65139 14.6667 1.66663 11.6819 1.66663 8.00004C1.66663 4.31814 4.65139 1.33337 8.33329 1.33337C12.0152 1.33337 15 4.31814 15 8.00004Z"
      fill="#1F8562"
    />
    <path
      d="M12.9999 8.00004C12.9999 10.5774 10.9105 12.6667 8.33321 12.6667C5.75588 12.6667 3.66654 10.5774 3.66654 8.00004C3.66654 5.42271 5.75588 3.33337 8.33321 3.33337C10.9105 3.33337 12.9999 5.42271 12.9999 8.00004Z"
      fill="#FAFAFA"
    />
    <path
      d="M10.9999 8.00004C10.9999 9.4728 9.80597 10.6667 8.33321 10.6667C6.86045 10.6667 5.66654 9.4728 5.66654 8.00004C5.66654 6.52728 6.86045 5.33337 8.33321 5.33337C9.80597 5.33337 10.9999 6.52728 10.9999 8.00004Z"
      fill="#1F8562"
    />
  </svg>
);

const LocationInput = ({ type, value, onChange, onFocus, onResultSelect }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Debounced version of searchLocation
  const searchLocation = useCallback(
    debounce(async (query) => {
      setLoading(true);
      setError(""); // Clear any previous error
      const params = { q: query, format: "json", addressdetails: 1 };
      try {
        const response = await axios.get(
          "https://nominatim.openstreetmap.org/search",
          { params }
        );
        const filteredResults = response.data.filter(
          (location) =>
            location.address && location.address.country_code === "id"
        );

        console.log(filteredResults);

        if (filteredResults.length === 0) {
          setError(
            "No address found in Indonesia. Please try a different location."
          );
        }
        setSearchResults(filteredResults);
      } catch (e) {
        setError("Error fetching location data");
        console.error("Error fetching location data:", e);
      } finally {
        setLoading(false);
      }
    }, 300), // Delay of 300ms
    []
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    onChange(query);

    if (query.length >= 3) {
      searchLocation(query);
    } else {
      setSearchResults([]);
      setError("");
    }
  };

  const handleResultClick = (result) => {
    onResultSelect(result, type);
    setSearchResults([]); // Clear results after selection
  };

  return (
    <>
      <li className="timeline-item pb-2">
        <div className="timeline-icon">
          {type === "start" ? StartIcon : EndIcon}
        </div>
        <div className="search-container">
          <input
            type="text"
            className="form-control"
            placeholder={
              type === "start"
                ? "Your starting point"
                : "Your destination point"
            }
            value={value}
            onChange={handleInputChange}
            onFocus={() => onFocus(type)}
          />

          {loading && (
            <ul className="search-results list-group loading mt-2">
              Loading...
            </ul>
          )}
          {error && (
            <ul className="search-results list-group error mt-2">{error}</ul>
          )}

          {searchResults.length > 0 && !loading && !error && (
            <SearchResults
              results={searchResults}
              onResultClick={handleResultClick}
            />
          )}

          {/* {type === "start" ? <hr className="my-2" /> : ""} */}
        </div>
      </li>
    </>
  );
};
export { StartIcon, EndIcon };
export default LocationInput;
