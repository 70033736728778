import { createSlice } from "@reduxjs/toolkit";

export const tripSlice = createSlice({
  name: "trip",
  initialState: {
    startPoint: null,
    endPoint: null,
    transportMode: null,
    allTransportModes: null,
    summary: null,
    alternate: null,
  },
  reducers: {
    setStartPoint: (state, action) => {
      state.startPoint = action.payload;
    },
    setEndPoint: (state, action) => {
      state.endPoint = action.payload;
    },
    setAllTransportModes: (state, action) => {
      state.allTransportModes = action.payload;
    },
    selectTransportMode: (state, action) => {
      state.transportMode = action.payload;
    },
    setDistanceAndDuration: (state, action) => {
      state.summary = action.payload.summary;
      state.alternate = action.payload.alternate;
    },
    resetTrip: (state) => {
      // Reset state to the initial state
      return {
        ...state,
        startPoint: null,
        endPoint: null,
        transportMode: null,
        allTransportModes: null,
        summary: null,
        alternate: null,
      };
    },
  },
});

export const {
  setStartPoint,
  setEndPoint,
  selectTransportMode,
  setDistanceAndDuration,
  setAllTransportModes,
  resetTrip,
} = tripSlice.actions;

export default tripSlice.reducer;
