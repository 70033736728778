// src/App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Calculate from "./pages/Calculate";
import Summary from "./pages/Summary";
import Register from "./pages/events/Register";
import VerifyOtp from "./pages/events/VerifyOtp";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/calculate" element={<Calculate />} />
      <Route path="/summary" element={<Summary />} />

      <Route path="/uihm" element={<Register />} />
      <Route path="/uihm/verify-otp" element={<VerifyOtp />} />
    </Routes>
  );
}

export default App;
