import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetTrip, setDistanceAndDuration } from "../features/tripSlice";

const Calculate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startPoint, endPoint, transportMode, allTransportModes } =
    useSelector((state) => state.trip);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleRetry = () => {
    setError(null);
    setLoading(true);
    fetchData();
  };

  const calculateCost = (transportMode, kilometers) => {
    switch (transportMode.type) {
      case "Car":
        return 1295 * kilometers;
      case "Motorcycle":
        return Math.ceil(266.625 * kilometers);
      case "Train":
        const first25 = 3000.0;
        const every10 =
          kilometers <= 25 ? 0 : Math.ceil((kilometers - 25) / 10) * 1000;
        return first25 + every10;
      case "Bus":
        const tjFarePerTripInIDR = 3500.0;
        const tjLongestCorridorInKm = 29.9;
        const tjMaximumDistanceInKm = (110.0 / 100.0) * tjLongestCorridorInKm;
        const isTransjakartaBusTrip = kilometers <= tjMaximumDistanceInKm;

        if (isTransjakartaBusTrip) return tjFarePerTripInIDR;
        if (kilometers <= 40.0) return 25000;
        if (kilometers <= 45.0) return 30000;

        const isInRange = (distance, from, to) =>
          distance >= from && distance <= to;

        let baseCostInIDR, baseDistanceInKm, farePerKmInIDR;

        if (isInRange(kilometers, 46, 60)) {
          baseCostInIDR = 30000;
          baseDistanceInKm = 45;
          farePerKmInIDR = 3000;
        } else if (isInRange(kilometers, 61, 120)) {
          baseCostInIDR = 75000;
          baseDistanceInKm = 60;
          farePerKmInIDR = 1200;
        } else if (isInRange(kilometers, 121, 200)) {
          baseCostInIDR = 147000;
          baseDistanceInKm = 120;
          farePerKmInIDR = 700;
        } else {
          baseCostInIDR = 203000;
          baseDistanceInKm = 200;
          farePerKmInIDR = 350;
        }

        const extraDistanceInKm = kilometers - baseDistanceInKm;
        const extraCostInIDR = extraDistanceInKm * farePerKmInIDR;
        return extraCostInIDR + baseCostInIDR;
      case "Walk":
        return 0;
      default:
        return 0;
    }
  };

  const fetchData = async () => {
    const osrmUrl = `http://router.project-osrm.org/route/v1/${transportMode.group}/${startPoint.lon},${startPoint.lat};${endPoint.lon},${endPoint.lat}?overview=false&geometries=geojson`;

    try {
      const response = await fetch(osrmUrl);

      if (response.status === 400) {
        const errorData = await response.json();
        if (errorData.code === "NoRoute") {
          setError({
            title: "The route you are trying </br>to track is too far",
            description: "Please try a different route",
          });
        } else {
          setError({
            title: "An error occurred while calculating the route",
            description: "Please try again.",
          });
        }
        setLoading(false);
        return;
      }

      const data = await response.json();

      if (data.routes && data.routes.length > 0) {
        const route = data.routes[0];
        const distanceInKm = (route.distance / 1000).toFixed(2);
        const durationInMinutes = route.duration / 60;
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = Math.floor(durationInMinutes % 60);

        const calculatedEmission = (distanceInKm * transportMode.co2e).toFixed(
          2
        );

        let alternateMode;
        if (["Bus", "Train"].includes(transportMode.type)) {
          alternateMode = allTransportModes.find((mode) => mode.type === "Car");
        } else if (["Motorbike", "Car"].includes(transportMode.type)) {
          alternateMode = allTransportModes.find((mode) => mode.type === "Bus");
        }

        const alternateEmission = (distanceInKm * alternateMode.co2e).toFixed(
          2
        );

        dispatch(
          setDistanceAndDuration({
            summary: {
              distance: distanceInKm,
              duration: { hours, minutes },
              emission: calculatedEmission,
              cost: calculateCost(transportMode, distanceInKm),
            },
            alternate: {
              emission: alternateEmission,
              type: alternateMode.type,
              is_public: alternateMode.is_public,
              icon: alternateMode.icon,
              cost: calculateCost(alternateMode, distanceInKm),
            },
          })
        );

        navigate("/summary");
      } else {
        console.error("No route found");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data from OSRM:", error);
      setError({
        title: "An error occurred while fetching the route data",
        description: "Please try again.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!startPoint || !endPoint) {
      navigate("/");
      return;
    }
    fetchData();
  }, [
    startPoint,
    endPoint,
    transportMode,
    allTransportModes,
    dispatch,
    navigate,
  ]);

  if (loading) {
    return <LoadingDots />;
  }

  return (
    <div className="container">
      <div className="logo">
        <img
          src="/logo.png"
          srcSet="/logo.png 1x, /logo@2x.png 2x"
          alt="Tracco Logo"
        />
        Tracco
      </div>
      <div className="content d-flex align-items-center">
        <ErrorDisplay errorMessage={error} onRetry={handleRetry} />
      </div>
      <div className="sticky-button">
        <button
          className="btn btn-outline-success btn-outline-confirm w-100"
          onClick={() => {
            dispatch(resetTrip());
            navigate("/");
          }}
        >
          Try Different Route
        </button>
      </div>
    </div>
  );
};

const ErrorDisplay = ({ errorMessage, onRetry }) => {
  return (
    <div className="error-container">
      <img
        src="/img/404.png"
        alt="Error Illustration"
        className="error-image"
      />
      <h3
        className="error-title"
        dangerouslySetInnerHTML={{ __html: errorMessage.title }}
      />
      <p
        className="error-description"
        dangerouslySetInnerHTML={{ __html: errorMessage.description }}
      />
    </div>
  );
};

const LoadingDots = () => {
  return (
    <div className="loading-container">
      <div className="loading-dots">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p>Summarizing your trip</p>
    </div>
  );
};

export default Calculate;
