// components/SearchResults.js
import React from "react";

const SearchResults = ({ results, onResultClick }) => {
  return (
    <ul className="search-results list-group mt-2">
      {results.map((result, index) => (
        <li
          key={index}
          className="list-group-item"
          onClick={() => onResultClick(result)}
        >
          <div className="icon-wrapper">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="24" rx="12" fill="#F5F5F5" />
              <g clipPath="url(#clip0_15160_13646)">
                <path
                  d="M16.5 11.5C16.5 15 12 18 12 18C12 18 7.5 15 7.5 11.5C7.5 10.3065 7.97411 9.16193 8.81802 8.31802C9.66193 7.47411 10.8065 7 12 7C13.1935 7 14.3381 7.47411 15.182 8.31802C16.0259 9.16193 16.5 10.3065 16.5 11.5Z"
                  fill="#1F8562"
                  stroke="#1F8562"
                  strokeWidth="0.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 13C12.8284 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8284 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13Z"
                  stroke="white"
                  strokeWidth="0.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_15160_13646">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(6 6.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="place-wrapper">
            <div className="name">{result.name}</div>
            <div className="detail">{result.display_name}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default SearchResults;
